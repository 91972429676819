import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/style.css'
Vue.config.productionTip = false

import {
    VueJsonp
} from 'vue-jsonp'
Vue.use(VueJsonp)

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading, {})

import Toast from 'vue-easy-toast'
Vue.use(Toast, {
    duration: 1500,
    horizontalPosition: 'center',
    verticalPosition: 'top'
})

import Tools from './utils/tools'
Vue.use({
    install(Vue, options) {
        Vue.prototype.decodeContent = Tools.decodeContent
        Vue.prototype.calcMaxPage = Tools.calcMaxPage
        Vue.prototype.index2Chinese = Tools.indexToChinese
        Vue.prototype.formatDuration = Tools.formatDuration
        Vue.prototype.compareDate = Tools.compareDate
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')