<template>
  <div id="app">
    <Header />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <div class="footer">
      主办: 河北省应急管理厅 <br />
      冀ICP备14020218号 冀公网安备 13011002000239号
    </div>
    <a href="#" class="btn-top" style="opacity: 1"
      ><i class="iconfont icon-pre-page"></i
    ></a>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      userInfo: null,
    };
  },
  created() {
    window.$loading = this.$loading;
    let user = window.localStorage.getItem("userInfo");
    let token = window.localStorage.getItem("token");
    if (user) {
      this.userInfo = JSON.parse(user);
      this.$store.dispatch("updateUser", this.userInfo);
    }
    if (token) {
      this.$store.dispatch("updateToken", token);
    }
  },

  methods: {},
};
</script>
<style>
#app {
  padding-top: 58px;
}

.header {
  padding: 10px 15px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.bg-blur {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
.header-logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: #000;
  text-decoration: none;
}
.header-logo .logo {
  margin-right: 10px;
}
.header .logo {
  height: 40px;
  transform: scale(1);
}

.footer {
  min-height: 190px;
  background: #333333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
}
a.btn-top {
  width: 54px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  text-decoration: none;
  border-radius: 10px 0 0 10px;
  position: fixed;
  z-index: 1049;
  display: block;
  right: 0;
  bottom: 40px;
  background: rgba(255, 255, 255, 1);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 102, 0.3);
  box-shadow: 0 0 30px rgba(0, 0, 102, 0.3);
}

a.btn-top .icon-pre-page {
  font-size: 24px;
  color: #387fd7;
  text-decoration: none;
  transform: rotate(90deg);
  display: block;
}
</style>
