let baseUrl = "";
switch (process.env.NODE_ENV) {
    case 'development':
        baseUrl = 'https://api.hbzhengwu.com/mem/api/'
        break
    case 'production':
        baseUrl = 'https://api.hbzhengwu.com/mem/api/'
        break
}

export default baseUrl;