<template>
  <div>
    <div class="carousel-wrapper"
         @mouseover="carouselOver"
         @mouseout="carouselOut">
      <!-- 图片容器 -->
      <div class="img-content">
        <!-- 
            这里初始化只加载第一张图片，用户点击或定时器再加载其他图片
            这种加载模式也可优化初始化页面加载速度
           -->
        <img :src="imgSrc"
             :width="this.width"
             :height="this.height"
             @click="clickBanner" />
      </div>
      <!-- 底部圆形小图标 -->
      <div class="indicator-content"
           ref="indicator">
        <ul class="indicator-list">
          <li class="indicator-item"
              v-for="(item, index) in defaultList"
              :key="item.id">
            <a class="indicator-icon"
               @mouseover="iconOver(index)"
               :class="index === pos ? 'active' : '' "></a>
          </li>
        </ul>
      </div>
      <!-- 上一张、下一张按钮 -->
      <div class="control-content">
        <div title="上一张"
             class="prev"
             @click="toggleImg('prev')">
          <span class="carousel-control-prev-icon"></span>
        </div>
        <div title="下一张"
             class="next"
             @click="toggleImg('next')">
          <span class="carousel-control-next-icon"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 定义每个小图标的宽度
const iconWidth = 30;
export default {
  /*
   * 接收父组件的值,轮播图宽度、高度、相邻两张图片切换的间隔时间、轮播图路径数组
   */
  props: {
    width: {
      type: Number,
      default: 750
    },
    height: {
      type: Number,
      default: 390
    },
    interval: {
      type: Number,
      default: 2000
    },
    list: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  watch: {
    list: {
      handler(newVal) {
        this.defaultList = newVal
      },
      immediate: true
    }
  },
  data() {
    return {
      // 子组件中模拟的轮播图数组，实际开发中应由父组件传入
      defaultList: [],
      // 默认显示第一张图片，数组下标为0
      pos: 0,
      // 定时器
      startmove: ''
    }
  },
  created() {
    // 调用定时器
    this.startmove = setInterval(this.bannerMove, this.interval);
    // 动态计算底部小图标容器的宽度和水平居中
    this.$nextTick(() => {
      this.$refs.indicator.style.width = iconWidth * this.defaultList.length + 'px';
      this.$refs.indicator.style.marginLeft = -(iconWidth / 2) * this.defaultList.length + 'px';
    })
  },
  computed: {
    // icon样式
    iconClass() {
      return '';
    },
    // 图片路径
    imgSrc() {
      if (this.pos < this.defaultList.length) {
        return this.defaultList[this.pos].image || this.defaultList[this.pos].cover;
      }
      return ""
    }
  },
  beforeDestroy() {
    // 组件销毁前,清除监听器
    clearInterval(this.startmove);
  },
  methods: {
    // 图片上、下一张切换
    toggleImg(type) {
      if (type === 'prev') {
        this.pos === 0 ? this.pos = this.defaultList.length - 1 : this.pos--;
      } else {
        this.pos === this.defaultList.length - 1 ? this.pos = 0 : this.pos++;
      }
    },
    // 小图标鼠标hover事件
    iconOver(index) {
      if (isNaN(index)) return;
      this.pos = index;
    },
    // 图片自动切换定时器方法
    bannerMove() {
      this.pos === this.defaultList.length - 1 ? this.pos = 0 : this.pos++;
    },
    // 鼠标移入图片区域，清除定时器，防止和用户切换图片事件冲突
    carouselOver() {
      clearInterval(this.startmove);
    },
    // 鼠标移出图片区域，开启定时器
    carouselOut() {
      this.startmove = setInterval(this.bannerMove, this.interval);
    },
    clickBanner() {
      let item = this.defaultList[this.pos]
      this.$emit('click', item)
    }
  },
  components: {

  }
};
</script>

<style scoped>
.carousel-wrapper {
  position: relative;
  width: 750px;
  height: 390px;
  margin-bottom: 10px;
  margin-top: 20px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.carousel-wrapper:hover .control-content .prev,
.carousel-wrapper:hover .control-content .next {
  opacity: 1;
}

.carousel-wrapper .img-content {
  position: absolute;
  top: 0;
  left: 0;
  width:750px;
  height: 390px;
}
/* .carousel-wrapper .img-content img{
  width:100%;
  height: 100%;
  object-fit: cover;
} */
.carousel-wrapper .img-content a {
  border-radius: 20px;
  overflow: hidden;
  transform: translateY(0);
}

.carousel-wrapper .img-content a:hover img {
  transform: translateY(0) !important;
}

.carousel-wrapper .indicator-content {
  position: absolute;
  bottom: 30px;
  left: 50%;
  /* margin-left: -45px; */
  /* width: 90px; */
  height: 22px;
  background: rgba(223, 223, 223, 0.6);
  border-radius: 11px;
}

.carousel-wrapper .indicator-content .indicator-list {
  overflow: hidden;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.carousel-wrapper .indicator-content .indicator-list .indicator-item {
  float: left;
  width: 30px;
  height: 22px;
}

.carousel-wrapper
  .indicator-content
  .indicator-list
  .indicator-item
  .indicator-icon {
  display: inline-block;
  margin: 3px 0 0 7px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
}

.carousel-wrapper
  .indicator-content
  .indicator-list
  .indicator-item
  .indicator-icon.active {
  background: #df3526;
}

.carousel-wrapper .control-content .prev,
.carousel-wrapper .control-content .next {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  right: 1px;
  width: 40px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: rgba(63, 64, 65, 0.7);
  color: #fff;
  font-size: 40px;
  cursor: pointer;
  opacity: 0.2;
}

.carousel-wrapper .control-content .prev {
  left: 0;
}

.carousel-wrapper .control-content .next {
  right: 0;
}
</style>