import {
    get,
    post,
    upload
} from '@/network/request.js';

export const login = p => post('account/pclogin', p)
export const updateUser = p => post('profile/complete', p)
export const userDetail = p => get('profile/detail', {})
export const uploadFile = p => upload('profile/upload', p)

export const getBaseData = p => get('general/option?key=' + p, {})

export const fetchBanner = p => get('general/banner', p)
export const paperList = p => get('paper/index', p)
export const paperDetail = p => get('paper/detail/' + p, {});
// 风采/通知/政策 收藏
export const toPaperFavourite = p => post('paper/favourite', p);
//通告 政策分类
export const companyCategory = p => get('paper/subType?code=' + p, {});
//企业名录列表
export const companyList = p => get('company/index', p)
//企业名录详情
export const companyDetail = p => get('company/detail/' + p, {})
//企业名录收藏
export const compaynFavourite = p => post('company/favourite', p)
//社会企业分类
export const socialCategory = p => get('paper/subType?code=social', p)
//专家列表
export const expertList = p => get('expert/index', p)
//专家详情
export const expertDetail = p => get('expert/detail/' + p, {})
//专家收藏
export const expertFavourite = p => post('expert/favourite', p)
//附件列表
export const fetchFileList = p => get('company/attachment', p)
//复审名单
export const fetchRecheckList = p => get('company/recheckList?recheck_year=2023', p)
//企业复审
export const companyRecheck = p => post('company/apply', p)
//答疑解惑列表
export const fetchQuestionList = p => get('mentoring/index', p)
export const fetchQuestionDetail = p => get('mentoring/detail/' + p, {})
export const questionFavourite = p => post('mentoring/favourite', p)
export const submitQuestion = p => post('mentoring/quiz', p)
export const myQuestionList = p => get('mentoring/quiz', p)
export const companyRegister = p => post('profile/companyApply', p)

/**
 * 应急管理平台
 */
//文章列表
export const articleList = p => get('general/article', p)
export const articleDetail = p => get('general/article/' + p, {})
//短视频子分类
export const videoCategoryList = p => get('video/category', p)
export const videoList = p => get('video/index', p)
export const videoDetail = p => get('video/detail?id=' + p, {})
export const recommendVideoList = p => get('video/recommend?id=' + p, {})
export const videoFavourite = p => post('video/favourite', p)
export const safetyFavourite = p => post('profile/favourite', p)

//信息联动
export const missionBanners = p => get('news/banner?region=' + p, {})
export const missionList = p => get('news/index', p)
export const missionDetail = p => get('news/detail/' + p, {})
export const missionFavourite = p => post('news/favourite', p)
export const areaList = p => get('general/area', p)

//志愿者
export const eventList = p => get('event/index', p)
export const eventDetail = p => get('event/detail/' + p, {})
export const eventFavourite = p => post('event/favourite', p)
export const colunteerList = p => get('volunteer/index', p)
export const colunteerDetail = p => get('volunteer/detail/' + p, {})
export const colunteerFavourtie = p => post('volunteer/favourite', p)
export const colunteerFavourtieList = p => get('volunteer/favourite', p)
export const colunteerApply = p => post('volunteer/apply', p)
export const eventApply = p => post('event/apply', p)
export const joinGroup = p => post('volunteer/join', p)
export const singleTopList = p => get('event/top', p)
export const regionTopList = p => get('event/regionTop', p)
export const eventTopList = p => get('event/eventTop', p)
export const eventType = p => get('event/type', p)
export const postEvent = p => post('event/index', p)
export const clockEvent = p => post('event/clock', p)
//个人中心

//活动列表
export const myEvents = p => get('profile/myEvent', p)
export const myPostedEvent = p => get('profile/myEvent', p)
export const myApply = p => get('company/myApply', p)
export const myManagedEvent = p => get('profile/myManagedEvent', p)

//收藏
export const safetyFavouriteList = p => get('profile/favourite', p)
//信息联动
export const newsFavouriteList = p => get('news/favourite', p)
export const videoFavouriteList = p => get('video/favourite', p)
export const questionFavouriteList = p => get('mentoring/favourite', p)
export const paperFavouriteList = p => get('paper/favourite', p);
export const compaynFavouriteList = p => get('company/favourite', p)
export const eventFavouriteList = p => get('event/favourite', p)

export const myOrgMemberList = p => get('volunteer/groupMembers', p)
export const applyOrgMember = p => post('volunteer/approve', p)
export const myOrgEventMemberList = p => get('event/members', p)
export const applyOrgEventMember = p => post('event/memberApprove', p)

export const getUploadInfo = p => get('profile/tempkey', p)