import Vue from 'vue'
import axios from 'axios'
import Toast from 'vue-easy-toast'
import Loading from 'vue-loading-overlay';

import QS from 'qs';
import Router from '@/router/index'
import Store from '@/store/index'

import baseUrl from './baseUrl';

// let baseURL
// if (process.env.NODE_ENV === 'development') {
//   baseURL = 'https://api.hbzhengwu.com/mem/api/'
// } else {
//   baseURL = 'https://data-design.hbzhengwu.com/api/'
// }
// console.log("evn is " + process.env.NODE_ENV);
// console.log("url is " + baseURL);
console.log("base_url is " + process.env.VUE_APP_BASE_URL, );
// axios.defaults.timeout = 6000;                
// axios.defaults.baseURL = baseURL;
// axios.defaults.responseType = "json";
// axios.defaults.withCredentials = false;

let loading = null;

const service = axios.create({
  baseURL: baseUrl, // api base_url,
  timeout: 60 * 1000, // 请求超时时间,
  headers: {
    "Content-Type": "application/json"
  },
});

service.interceptors.request.use(
  config => {
    let show = false
    if (loading == null) {
      if (config.method == "post") {
        show = config.data.showLoading || false
      } else {
        show = config.showLoading || false
      }
    }
    if (show && loading == null) {
      loading = window.$loading.show()
    }
    var token = window.localStorage.getItem("token")
    if (token && token != "" && token != "undefined") {
      config.headers.token = token
    }
    if (config.url == 'profile/upload')
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data'
      }
    config.headers.api = 'I6cqdxUUOsP6zlj'
    return config
  },
  error => {
    return Promise.reject(error);
  }
)

service.interceptors.response.use(
  response => {
    if (loading != null) {
      loading.hide()
      loading = null;
    }
    if (response.status == 200) {
      if (response.data.code == 20000 && response.data != null) {
        return Promise.resolve(response.data.data)
      } else {
        switch (response.data.code) {
          case 10001:
            Vue.toast("请重新登录")
            Store.dispatch("logout");
            Router.replace({
              path: '/'
            })
            break;
          case 30001:
            Vue.toast(response.data.message)
            setTimeout(() => {
              // Router.replace({
              //   path: '/'
              // })
            }, 1000);
            break;
          default:
            break;
        }
        return Promise.reject(response.data.message)
      }
    } else {
      return Promise.reject(response.errMsg)
    }
  }
)



export function get(url, params) {
  return new Promise((resolve, reject) => {
    service.get(url, {
        params: params,
        showLoading: params ? params.showLoading || false : false
      })
      .then(res => {
        resolve(res);
      })
      .catch(msg => {
        reject(msg)
      })
  });
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    service.post(url, {
        ...params,
        showLoading: params ? params.showLoading || false : false
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}

export function upload(url, data) {
  return new Promise((resolve, reject) => {
    service
      .post(url, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err)
      });
  });
}