import {
    Base64
} from "js-base64";


function calcMaxPage(total = 0, size = 10) {
    let num = total % size == 0 ? total / 10 : parseInt(total / size) + 1;
    return num > 0 ? num : 1
}

function decodeContent(content = "") {
    return Base64.decode(content)
}

function formatDuration(time) {
    let hour = time / 60;
    let min = (time - hour * 60) / 60
    let sencond = time - hour * 60 - min * 60
    return hour + "h" + min + "′" + sencond + "″"
}

function indexToChinese(idx) {
    if (idx == 0) {
        return "首"
    } else if (idx == 1) {
        return "二"
    } else if (idx == 2) {
        return "三"
    } else if (idx == 3) {
        return "四"
    } else if (idx == 4) {
        return "五"
    }
}

function compareDate(timeA, timeB) {
    let staytimeGap = new Date(timeB).getTime() - new Date(timeA).getTime();
    let stayHour = Math.floor(staytimeGap / (3600 * 1000)); // 小时
    let leave1 = staytimeGap % (3600 * 1000);
    let stayMin = Math.floor(leave1 / (60 * 1000)); // 分钟
    let leave2 = leave1 % (60 * 1000);
    let staySec = Math.floor(leave2 / 1000); // 秒
    return staytimeGap > 0
}

function cloudPathToLocal(url) {
    const location = url
    let path = location.split(".com/")[1];
    path = "https://vote-image.hbzhengwu.com/" + path;
    return path;
}

export default {
    decodeContent,
    calcMaxPage,
    indexToChinese,
    formatDuration,
    compareDate,
    cloudPathToLocal
}