import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [{
    path: "/home",
    redirect: "/",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/companynotice",
    name: "CompanyNotice",
    component: () => import("../views/company/CompanyNotice.vue"),
  },
  {
    path: "/companyarticle/:code/:id",
    name: "CompanyArticle",
    component: () => import("../views/company/CompanyArticle.vue"),
  },
  {
    path: "/companylist",
    name: "CompanyList",
    component: () => import("../views/company/CompanyList.vue"),
  },
  {
    path: "/companydetail/:id/:type",
    name: "CompanyDetail",
    component: () => import("../views/company/CompanyDetail.vue"),
  },
  {
    path: "/companyers",
    name: "CompanyERS",
    component: () => import("../views/company/CompanyERS.vue"),
  },
  {
    path: "/expertcenter",
    name: "ExpertCenter",
    component: () => import("../views/company/ExpertCenter.vue"),
  },
  {
    path: "/expertlist",
    name: "ExpertList",
    component: () => import("../views/company/ExpertList.vue"),
  },
  {
    path: "/expertdetail/:id",
    name: "ExpertDetail",
    component: () => import("../views/company/ExpertDetail.vue"),
  },
  {
    path: "/eqlist",
    name: "EqList",
    component: () => import("../views/company/EqList.vue"),
  },
  {
    path: "/eqdetail/:id",
    name: "EqDetail",
    component: () => import("../views/company/EqDetail.vue"),
  },
  {
    path: "/videolist",
    name: "VideoList",
    component: () => import("../views/safety/VideoList.vue"),
  },
  {
    path: "/videodetail/:id",
    name: "VideoDetail",
    component: () => import("../views/safety/VideoDetail.vue"),
  },
  {
    path: "/missionlist",
    name: "MissionList",
    component: () => import("../views/safety/MissionList.vue"),
  },
  {
    path: "/missiondetail/:id",
    name: "MissionDetail",
    component: () => import("../views/safety/MissionDetail.vue"),
  },
  {
    path: "/safetylist",
    name: "SafetyList",
    meta: {
      noCache: true,
      keepAlive: true,
    },
    component: () => import("../views/safety/SafetyList.vue"),
  },
  {
    path: "/safetydetail/:id",
    name: "SafetyDetail",
    component: () => import("../views/safety/SafetyDetail.vue"),
  },
  {
    path: "/eventlist/:type",
    name: "EventList",
    meta: {
      noCache: true,
      keepAlive: true,
    },
    component: () => import("../views/volunteer/EventList.vue"),
  },
  {
    path: "/eventdetail/:type/:id",
    name: "EventDetail",
    component: () => import("../views/volunteer/EventDetail.vue"),
  },
  // {
  //   path: "/volclock/:id",
  //   name: "VolClockIn",
  //   component: () => import("../views/volunteer/VolClockIn.vue"),
  // },
  {
    path: "/volpersonlogin",
    name: "VolPersonLogin",
    component: () => import("../views/volunteer/VolPersonLogin.vue"),
  },
  {
    path: "/volorglogin",
    name: "VolOrgLogin",
    component: () => import("../views/volunteer/VolOrgLogin.vue"),
  },
  {
    path: "/orglist",
    name: "OrgList",
    component: () => import("../views/volunteer/OrgList.vue"),
  },
  {
    path: "/orgdetail/:id",
    name: "OrgDetail",
    component: () => import("../views/volunteer/OrgDetail.vue"),
  },
  {
    path: "/volrequire",
    name: "volRequire",
    component: () => import("../views/volunteer/volRequire.vue"),
  },
  {
    path: "/volrank",
    name: "volRank",
    component: () => import("../views/volunteer/volRank.vue"),
  },
  {
    path: "/myprofile",
    name: "MyProfile",
    component: () => import("../views/mycenter/MyProfile.vue"),
  },
  {
    path: "/volnews",
    name: "VolunteerNews",
    component: () => import("../views/volunteer/VolNewsList.vue"),
  },
  {
    path: "/volnews/:id",
    name: "VolunteerNewsDetail",
    component: () => import("../views/volunteer/VolNewsDetail.vue"),
  },
  {
    path: "/companynews",
    name: "CompanyNews",
    component: () => import("../views/company/CompanyNewsList.vue"),
  },
  {
    path: "/companynews/:id",
    name: "CompanyNewsDetail",
    component: () => import("../views/company/CompanyNewsDetail.vue"),
  },
  {
    path: "/uptest",
    name: "UploadTest",
    component: () => import("../views/UploadTest.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.path == "/myCenter" || to.path == "/companyers") {
    let token = window.localStorage.getItem("token");
    if (!token) {
      Vue.toast("请先登录");
      next("/");
    }
  }
  if (to.path == "/volrequire") {
    let userJson = window.localStorage.getItem("userInfo");
    let userInfo = JSON.parse(userJson);
    if (userInfo && userInfo.is_volunteer_group == "1") {
      next();
    } else {
      Vue.toast("仅组织者可以发需求");
      next("/");
    }
  }
  if (to.path == "/volpersonlogin") {
    let userJson = window.localStorage.getItem("userInfo");
    let userInfo = JSON.parse(userJson);
    if (userInfo && userInfo.is_volunteer == "1") {
      Vue.toast("您已是志愿者");
      next("/");
    }
  }
  if (to.path == "/volorglogin") {
    let userJson = window.localStorage.getItem("userInfo");
    let userInfo = JSON.parse(userJson);
    if (userInfo && userInfo.is_volunteer_group == "1") {
      Vue.toast("您已加入组织");
      next("/");
    }
  }
  window.localStorage.setItem("headerName", to.path);
  next();
});

export default router;